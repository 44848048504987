import { type FC, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const Terms: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>利用規約: サイト分析、レポートはGrowth Support</title>
      </Helmet>
      <section className="p-terms" id="terms">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 text-gray-600">
          <header className="p-terms__header">
            <h1 className="p-terms__title">利用規約</h1>
          </header>
          <div className="p-terms__body">
            <div className="p-terms__contents">
              <div className="p-terms__content">
                <h2 className="p-chapter__title">第1章 総則</h2>
                <h3 className="p-article__title">第1条（利用規約の適用）</h3>
                <p className="p-terms__description">
                  「Growth
                  Support利用規約」（以下「利用規約」）は、fives株式会社（以下「当社」）が提供するサービス「Growth
                  Support」（以下「本サービス」）のご利用条件を、当社と本サービスの利用に関する契約を締結した者（以下「お客様」）の間で定めるものです。
                  <br />
                  お客様は、利用規約に同意して本サービスを利用する必要があり、利用規約の承諾を示す本サービスの同意欄をクリックした場合、利用規約に同意したものとします。
                </p>
              </div>
              <div className="p-terms__content">
                <h2 className="p-chapter__title">
                  第2章 契約の締結及び本サービスについて
                </h2>
                <h3 className="p-article__title">
                  第2条（本サービスの種類と内容）
                </h3>
                <ol>
                  <li className="p-article__description">
                    当社が提供する本サービスの内容は、以下に定めるとおりとします。
                    <br />
                    本サービスの内容：第三者が提供するプログラム（Google
                    Analytics、広告配信事業者、マーケティングオートメーション事業者等）によって、アクセスログの収集・整理・分析を行い、その結果をお客様に提供する。
                  </li>
                  <li className="p-article__description">
                    お客様は、当社と締結した利用契約の範囲内で、第三者に対して本サービスに基づくサービスを行うことができるものとします。
                  </li>
                  <li className="p-article__description">
                    本サービスは、当社または第三者が著作権、商標権その他の知的財産権（以下「著作権等」）を有するものであり、利用契約に特段の定めのない限り、お客様は、本サービスを複製、翻案、公衆送信（送信可能化を含む）、改造、逆コンパイル、逆アセンブル、リバースエンジニアリング等することはできないものとします。お客様は、利用契約等に基づいて本サービスに関する著作権等を取得するものではないことを確認します。
                  </li>
                  <li className="p-article__description">
                    本サービスには、第三者のサービス（以下「第三者サービス」）が含まれ、また、第三者サービスへのアクセス又は第三者サービスとの相互作用等が行われる場合があります。当社は、第三者サービスに対するいかなる保証または表明もせず、いかなる責任も負いません。お客様は、第三者サービスへのアクセス又は利用等について、当該第三者から別途契約の締結等を求められる場合があります。お客様は、当該契約等の条件を遵守し、又は本条第2項に従い第三者に対して本サービスに基づくサービスを行っている場合には、当該第三者に当該契約等の条件を遵守させるものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第3条（利用契約の締結等）</h3>
                <ol>
                  <li className="p-article__description">
                    本利用契約は、本サービスの利用を希望する者（以下、利用希望者）が、当社所定の利用登録申請を行い、当社がこれに対し、当社所定の方法により承諾の通知を発したときに成立するものとします。なお、利用希望者は、本利用規約の内容を承諾の上、かかる利用登録申請を行うものとし、利用登録申請が行われた時点で、当社は、利用希望者が本利用規約の内容を承諾したものとみなします。
                  </li>
                  <li className="p-article__description">
                    当社は、お客様が次の各号のいずれかに該当する場合には、申し込み時点に遡及して利用契約を無効とすることができます。
                    <ol>
                      <li className="p-article__description">
                        利用契約に虚偽の記載、誤記があったとき又は記入漏れがあったとき
                      </li>
                      <li className="p-article__description">
                        金銭債務その他利用契約に基づく債務の履行がなされないおそれがあるとき
                      </li>
                      <li className="p-article__description">
                        お客様への本サービスの提供が技術上困難であるとき
                      </li>
                      <li className="p-article__description">
                        その他当社が不適当と判断したとき
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    お客様が分析対象webサイト・機能等を追加する場合は、当該分の利用開始をもって本契約に追加（以下「契約追加」）されるものとします。契約追加の内容は、当社が請求する利用料金に反映されます。尚、契約追加の起算点は追加された時点と異なる場合があります。
                  </li>
                </ol>
                <h3 className="p-article__title">第4条（変更通知）</h3>
                <ol>
                  <li className="p-article__description">
                    お客様は、その商号若しくは名称、本店所在地若しくは住所、連絡先その他登録内容に変更が生じることとなったときは、速やかに当社に通知した上で、当社から要求された資料を提出するものとします。
                  </li>
                  <li className="p-article__description">
                    お客様が前項に従った通知を怠ったことに起因する、当社からの通知の不到達その他お客様又はその他の第三者が損害を被った場合であっても、当社は一切責任を負わないものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">
                  第5条（サービス内容の変更等）
                </h3>
                <p className="p-terms__description">
                  本サービスは、お客様に通知することなく、本サービスの都合によりサービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってお客様に生じた損害について一切の責任を負いません。
                </p>
                <h3 className="p-article__title">
                  第6条（一時的な中断及び提供停止）
                </h3>
                <ol>
                  <li className="p-article__description">
                    当社は、次の各号のいずれかに該当する場合には、お客様への事前の通知又は承諾を要することなく、本サービスの提供を中断することができるものとします。
                    <ol>
                      <li className="p-article__description">
                        本サービス用設備、又は当該設備を稼働する前提となる電気設備等に不具合、故障が発生した場合
                      </li>
                      <li className="p-article__description">
                        本サービス用設備への不正アクセスや外部攻撃などで、セキュリティ上の問題が発生した場合
                      </li>
                      <li className="p-article__description">
                        天災地変等不可抗力により本サービスを提供できない場合
                      </li>
                      <li className="p-article__description">
                        その他本サービスの運用上又は技術上の理由でやむを得ない場合
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    当社は、サービス用設備の定期点検又はソフトウェアアップデートを行う場合は、お客様に事前に通知の上、サービスの提供を一時的に中断できるものとします。
                  </li>
                  <li className="p-article__description">
                    当社は、お客様が第８条（本サービスからの利用契約の解除）第１項各号のいずれかに該当した場合のほか、利用契約等のいずれかの条項に違反した場合には、お客様への事前の通知若しくは催告を要することなく、本サービスの全部又は一部の提供を停止することができるものとします。
                  </li>
                  <li className="p-article__description">
                    前各項に定める事由のいずれかにより本サービスの提供を行わなかったことに起因して、お客様又はその他の第三者が損害を被った場合であっても、当社は一切責任を負わないものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第7条（利用期間）</h3>
                <ol>
                  <li className="p-article__description">
                    利用期間は、次の通りとします。
                    <ol>
                      <li className="p-article__description">
                        契約期間が定まっていない場合、本契約の成立より、利用開始日からお客様が本サービスを解約するまでとします。
                      </li>
                      <li className="p-article__description">
                        契約期間が定まっている場合、本契約の成立より、利用開始日から契約期間が満了するまでとします。但し、契約期間満了前までに当社指定の方法でお客様からの更新拒絶又は契約条件の変更等の申し出がない場合、本契約は同じ条件で更に自動的に更新されるものとし、以降も同様とします。尚、お客様は、本契約に契約追加した場合、追加前の本契約の契約期間が適用されます。
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    当社は、当社においてサービスの継続が困難な状況が生じた場合は、お客様へ通知することにより、本契約を解約することができます。
                  </li>
                </ol>
                <h3 className="p-article__title">
                  第8条（当社からの利用契約の解除）
                </h3>
                <ol>
                  <li className="p-article__description">
                    利用規約の他の規定にかかわらず、当社は、お客様が次の各号のいずれかに該当すると判断した場合、お客様への事前の通知若しくは催告を要することなく、利用契約の全部若しくは一部を解除することができるものとします。
                    <ol>
                      <li className="p-article__description">
                        当社への通知内容に虚偽記入があった場合
                      </li>
                      <li className="p-article__description">
                        当社の定める支払期日までに本サービス利用料金等の支払いを行わない場合
                      </li>
                      <li className="p-article__description">
                        手形又は小切手が不渡りとなった場合
                      </li>
                      <li className="p-article__description">
                        差押え、仮差押え若しくは競売の申立があった場合又は公租公課の滞納処分を受けた場合
                      </li>
                      <li className="p-article__description">
                        破産手続開始、特別清算手続開始、会社更生手続開始若しくは民事再生手続開始の申立があった場合又は信用状態に重大な不安が生じた場合
                      </li>
                      <li className="p-article__description">
                        監督官庁から営業許可の取消、停止等の処分を受けた場合
                      </li>
                      <li className="p-article__description">
                        利用契約等に違反し、当社が相当の期間を定めてかかる違反の是正を催告した後、当該違反が合理的な期間内に是正されない場合
                      </li>
                      <li className="p-article__description">
                        解散、事業の全部又は重要な一部の譲渡等の決議をした場合
                      </li>
                      <li className="p-article__description">
                        利用契約等におけるお客様の義務を履行することが困難となる事由が生じた場合
                      </li>
                      <li className="p-article__description">
                        暴力団、暴力団構成員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、特殊知能暴力団等のいわゆる反社会的勢力もしくは反社会的活動を行う団体に所属し、もしくは所属していた場合、又は密接な関係を有する場合
                      </li>
                      <li className="p-article__description">
                        自ら又は第三者を利用して、暴力的行為、詐欺、脅迫的言辞、偽計又は威力を用いた業務妨害行為等の不当な行為をした場合、または公序良俗に反する行為をした場合
                      </li>
                      <li className="p-article__description">
                        その他利用契約等を継続しがたい重大な事由が発生したとき
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    お客様は、前項各号のいずれかに該当した場合には、当社に対し負担する一切の金銭債務につき、当社から何ら通知催告がなくとも当然に期限の利益を喪失し、直ちにこれを支払わなければならないものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第9条（本サービスの廃止）</h3>
                <ol>
                  <li className="p-article__description">
                    利用規約の他の規定にかかわらず、当社は、次の各号のいずれかに該当する場合、本サービスの全部又は一部を廃止することができるものとし、当該廃止に伴い、当該廃止をする日（以下「廃止日」）をもって利用契約の全部又は一部を解除することができるものとします。
                    <ol>
                      <li className="p-article__description">
                        当社が、廃止日の30日前までに、本サービスの全部又は一部を廃止することをお客様に通知した場合
                      </li>
                      <li className="p-article__description">
                        当社が天災地変等不可抗力により本サービスを提供できなくなった場合
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    前項に基づき本サービスの全部又は一部を廃止する場合、当社は、いかなる責任も負わないものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第10条（利用料金）</h3>
                <ol>
                  <li className="p-article__description">
                    本サービスの利用料金、算定方法等は、本サービスの定めるとおりとします。なお、支払に必要な振込手数料その他の費用はお客様の負担とします
                  </li>
                  <li className="p-article__description">
                    お客様が前項に定める利用料金を支払わない場合には、本サービスは、第6条第3項の定めに従い、本サービスの全部または一部の提供を停止することができるものとします。
                  </li>
                </ol>
              </div>
              <div className="p-terms__content">
                <h2 className="p-chapter__title">第3章 お客様の義務等</h2>
                <h3 className="p-article__title">第11条（自己責任の原則）</h3>
                <ol>
                  <li className="p-article__description">
                    お客様は、本サービスの利用にあたり対象となるお客様サイトのアクセス解析情報、Webコンテンツ情報、その他、本サービス提供に必要な情報（以下、サービス対象サイト情報）について提供を承諾するものとし、当社が本サービス提供・改善、新サービス・機能の開発に役立てる目的の範囲内で当社又は第三者がサービス対象サイト情報にアクセスおよび使用することを承諾するものとします。
                  </li>
                  <li className="p-article__description">
                    本サービスを利用してお客様が提供または伝送する情報（コンテンツ）については、お客様の責任で提供されるものであり、当社はその内容等についていかなる保証も行わず、それに起因する損害についてもいかなる責任も負わないものとします。また、お客様が第2条第2項に従い第三者に対して本サービスに基づくサービスを行っている場合、当該第三者が提供または伝送する情報（コンテンツ）を当社が保管することについて、お客様は当該第三者から承諾を得るものとします。
                  </li>
                  <li className="p-article__description">
                    当社は、完全性、正確性、適法性、有用性、無害性等を保証するものではなく、お客様は自己の責任において、本サービスを利用するものとします。
                  </li>
                  <li className="p-article__description">
                    当社は、お客様の本サービスの利用にかかる行為およびその結果について、一切責任を負わないものとします。お客様が本サービスを利用したことにより、他のお客様または第三者の権利を侵害した場合、もしくは他のお客様または第三者との間で紛争が生じた場合、お客様が自らの責任と負担においてこれを解決するものとし、当社に損害を与えないよう必要な措置を講じなければならないものとします。
                  </li>
                  <li className="p-article__description">
                    当社は、理由の如何を問わず、当社の判断でサービスの全部もしくは一部の提供を中断または停止した場合、当社またはお客様が登録、保存等をしたデータの全部もしくは一部が消失した場合、または当社により削除もしくは消去された場合であっても、一切責任を負わないものとします。
                  </li>
                  <li className="p-article__description">
                    本条の定めは、当社が損害発生の可能性を事前に認識していた場合、およびお客様が損害発生の可能性を事前に知らされていた場合にも適用されます。
                  </li>
                </ol>
                <h3 className="p-article__title">
                  第12条（本サービス利用のための設備設定・維持）
                </h3>
                <ol>
                  <li className="p-article__description">
                    お客様は、自己の費用と責任において、本サービスを利用するために必要なお客様設備を設定し、本サービス利用のための環境を維持するものとします。
                  </li>
                  <li className="p-article__description">
                    お客様は、本サービスを利用するにあたり自己の責任と費用をもって、電気通信事業者等の電気通信サービスを利用してお客様設備をインターネットに接続するものとします。
                  </li>
                  <li className="p-article__description">
                    お客様設備又は前項に定めるインターネット接続並びに本サービス利用のための環境に不具合がある場合、当社はお客様に対してサービスの提供の義務を負わないものとし、当該不具合に起因して生じたお客様又はその他の第三者の損害につき責任を負わないものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第13条（バックアップ）</h3>
                <p className="p-article__description">
                  お客様が本サービスを利用したことにより記録されたデータは、お客様の管理によりバックアップ作業を行うものとします。当社は、バックアップデータが存在しないこと、またはバックアップ作業を適宜実施しなかったことに起因して生じたお客様又はその他の第三者の損害につき責任を負わないものとします。
                </p>
                <h3 className="p-article__title">
                  第14条（ユーザＩＤ及びパスワード）
                </h3>
                <ol>
                  <li className="p-article__description">
                    お客様は、ユーザＩＤ及びパスワードを第三者に開示、貸与、共有してはならず、第三者に漏洩することのないよう厳重に管理（パスワードの適宜変更を含む）しなければならないものとします。ユーザＩＤ及びパスワードの管理不備、使用上の過誤、第三者の使用等によりお客様又はその他の者が損害を被った場合であっても、当社は一切の責任を負わないものとします。
                  </li>
                  <li className="p-article__description">
                    第三者がお客様のユーザＩＤ及びパスワードを用いて、本サービスを利用した場合、当該行為はお客様の行為とみなされるものとし、お客様はかかる利用についての一切の責任を負うものとします。また、当該行為により当社が損害を被った場合は、お客様は当社に対し、当該損害を賠償するものとします。
                  </li>
                  <li className="p-article__description">
                    当社の故意又は過失によりお客様のユーザＩＤ及びパスワードが第三者に利用された場合は、前項は適用されないものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第15条（禁止事項）</h3>
                <ol>
                  <li className="p-article__description">
                    お客様は本サービスの利用に関して、以下の行為を行わないものとします。
                    <ol>
                      <li className="p-article__description">
                        虚偽の事実を申告して利用申込をすること
                      </li>
                      <li className="p-article__description">
                        当社若しくは第三者の著作権等その他の権利を侵害する行為、又は侵害するおそれのある行為
                      </li>
                      <li className="p-article__description">
                        利用契約等に違反して、第三者に本サービスを利用させる行為
                      </li>
                      <li className="p-article__description">
                        法令若しくは公序良俗に違反し、又は当社若しくは第三者に不利益を与える行為
                      </li>
                      <li className="p-article__description">
                        当社又は第三者の名誉若しくは信用を毀損する行為
                      </li>
                      <li className="p-article__description">
                        犯罪に結びつく又は結びつくおそれがある行為
                      </li>
                      <li className="p-article__description">
                        第三者になりすまして本サービスを利用する行為
                      </li>
                      <li className="p-article__description">
                        ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為
                      </li>
                      <li className="p-article__description">
                        第三者の設備等又は本サービス用設備の利用若しくは運営に支障を与える行為、又は与えるおそれのある行為
                      </li>
                      <li className="p-article__description">
                        その他利用契約等において禁止されている行為や当社が社会的常識に照らして相応しくないと判断した行為
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    お客様は、前項各号のいずれかに該当する行為がなされたことを知った場合、又は該当する行為がなされるおそれがあると判断した場合は、直ちに当社に通知するものとします。また、お客様の行為や提供した情報が前項各号のいずれかに該当や関連する情報である場合、事前にお客様に通知することなく、本サービスの全部又は一部の提供を一時停止することができるものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第16条（機密保持）</h3>
                <p className="p-article__description">
                  お客様は、本サービスを利用する上で知り得た、当社の営業上の秘密、技術、ノウハウ、その他本サービスに関連する一切の情報を、本サービス提供中はもちろん本サービス提供終了後においても、当社の書面による事前の承諾なくして、第三者に開示・漏洩しないものとします。
                </p>
              </div>
              <div className="p-terms__content">
                <h2 className="p-chapter__title">第4章 当社の義務等</h2>
                <h3 className="p-article__title">第17条（善管注意義務）</h3>
                <p className="p-article__description">
                  当社は、本サービスの提供に際し、善良なる管理者の注意をもって本サービスを提供するものとします。
                </p>
                <h3 className="p-article__title">
                  第18条（本サービス用設備の障害等）
                </h3>
                <ol>
                  <li className="p-article__description">
                    当社は、サービス用設備について障害が発生したことを知ったときは、遅滞なくお客様にその旨を通知するものとし、修理又は復旧に努めるものとします。
                  </li>
                  <li className="p-article__description">
                    上記のほか、本サービスに不具合が発生したときは、当該不具合を認識した当事者は、遅滞なく相手方に通知し、両者協議の上、各自の行うべき対応措置を決定し、それを実施するものとします。
                  </li>
                </ol>
              </div>
              <div className="p-terms__content">
                <h2 className="p-chapter__title">第5章 機密情報等の取り扱い</h2>
                <h3 className="p-article__title">
                  第19条（機密情報の取り扱い）
                </h3>
                <ol>
                  <li className="p-article__description">
                    お客様及び当社は、サービス遂行のため相手方より提供を受けた技術上又は営業上その他業務上の情報のうち、相手方が特に機密である旨あらかじめ書面で指定した情報で、提供の際に機密情報の範囲を特定し、機密情報である旨の表示を明記した情報（以下「機密情報」）を第三者に開示又は漏洩しないものとします。ただし、①相手方からあらかじめ書面による承諾を受けた場合、②本サービス遂行目的又は本サービスの改善や新サービスの開発等に役立てる目的のために、当社が第三者に開示する場合はこの限りではありません。①及び②の場合、機密情報の開示者は、機密情報の受領者に対して、本条と同等の機密保持義務を課すものとし、機密情報の受領者による機密情報の使用について責任を負うものとします。
                  </li>
                  <li className="p-article__description">
                    前項にかかわらず、次の各号に該当する資料および情報は機密情報に含まれないものとします。
                    <ol>
                      <li className="p-article__description">
                        機密保持義務を負うことなく既に保有している情報
                      </li>
                      <li className="p-article__description">
                        機密保持義務を負うことなく第三者から正当に入手した情報
                      </li>
                      <li className="p-article__description">
                        相手方から提供を受けた情報によらず、独自に開発した情報
                      </li>
                      <li className="p-article__description">
                        受領の前後を問わず公知となった情報
                      </li>
                      <li className="p-article__description">
                        第三者が権利を有するソフトウェアの著作権保持者により開示を義務づけられているもの
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    前項の定めにかかわらず、お客様及び当社は、機密情報のうち法令の定めに基づき又は権限ある官公署からの要求により開示すべき情報を、当該法令の定めに基づく開示先又は当該官公署に対し開示することができるものとします。この場合、お客様及び当社は、関連法令に反しない限り、当該開示前に開示する旨を相手方に通知するものとし、開示前に通知を行うことができない場合は開示後すみやかにこれを行うものとします。
                  </li>
                  <li className="p-article__description">
                    機密情報の提供を受けた当事者は、当該機密情報の管理に必要な措置を講ずるものとします。
                  </li>
                  <li className="p-article__description">
                    機密情報の提供を受けた当事者は、相手方より提供を受けた機密情報を、利用契約等を履行する目的の範囲内でのみ使用又は複製することができるものとします。複製がなされた場合、お客様及び当社は、当該複製された機密情報についても、本条に定める機密情報として取り扱うものとします。なお、利用契約等を履行するために必要な範囲を超える複製が必要な場合は、あらかじめ相手方から書面による承諾を受けるものとします。
                  </li>
                  <li className="p-article__description">
                    機密情報の提供を受けた当事者は、相手方の要請があったときは資料等（その複製物を含む）を相手方に返還又は消去するものとします。
                  </li>
                  <li className="p-article__description">
                    お客様および当社は、本利用契約が終了した場合、または相手方から要求された場合には、相手方から受領した機密情報について、書面、記録媒体等返却可能なものは直ちに返却し、電子情報等返却に適さないものは直ちに消去するものとします。
                  </li>
                  <li className="p-article__description">
                    本条の規定は、当社による本サービスの履行完了後、5年間有効に存続するものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">
                  第20条（個人情報の取り扱い）
                </h3>
                <ol>
                  <li className="p-article__description">
                    当社は、サービス遂行のため相手方より提供を受けた営業上その他業務上の情報に含まれる個人情報を、本サービス遂行目的又は本サービスの改善や新サービスの開発等に役立てる目的の範囲内でのみ使用し、本規約に別途定める場合を除き、個人情報を使用または開示しないものとします。
                  </li>
                  <li className="p-article__description">
                    お客様は、本サービス利用のため当社より提供を受けた個人情報を本サービス利用の範囲内でのみ使用し、第三者に開示または漏洩しないものとするとともに、個人情報の保護に関する法律を含めた関連法令を遵守するものとします。
                  </li>
                  <li className="p-article__description">
                    第１項にかかわらず、当社は、次の各号に該当する場合は、お客様からの個別の同意を得ることなく、個人情報を開示することができるものとします。
                    <ol>
                      <li className="p-article__description">
                        当社がサービスを提供するために必要な業務を第三者に委託するに際し、当該委託先に開示する場合
                      </li>
                      <li className="p-article__description">
                        当社がサービスの向上を検討するために必要な範囲で、個人情報の集計および分析を第三者に委託するに際し、当該委託先に開示する場合
                      </li>
                      <li className="p-article__description">
                        当社が個人情報または前号の集計および分析等により得られた統計データを、個人を識別または特定できない状態に加工した上で、当社の提携先その他の第三者に開示する場合
                      </li>
                      <li className="p-article__description">
                        裁判所または監督官庁等の行政機関から、法令の定めるところに従い、開示を要求された場合
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    当社は、前項に基づき個人情報を開示する場合、開示する個人情報を開示する目的の実現に必要最小限の範囲に限定するとともに、前項第3号および第4号の場合を除き、開示する相手方に対し、本規約により当社が負うのと同等の機密保持義務を課すものとします。
                  </li>
                  <li className="p-article__description">
                    当社は、個人情報の集計および分析等により得られた統計データについて、個人を識別または特定できない状態に加工した上で、当社の事業に利用することができるものとします。
                  </li>
                </ol>
              </div>
              <div className="p-terms__content">
                <h2 className="p-chapter__title">第6章 損害賠償等</h2>
                <h3 className="p-article__title">第21条（損害賠償の制限）</h3>
                <p className="p-article__description">
                  債務不履行責任、不法行為責任、その他法律上の請求原因の如何を問わず、本サービス又は利用契約等に関して、当社がお客様に対して負う損害賠償責任の範囲は、当社の責に帰すべき事由により又は本サービスが利用契約等に違反したことが直接の原因でお客様に現実に発生した通常の損害に限定され、損害賠償の額は当該事由が生じた利用契約の額を超えないものとします。なお、当社の責に帰すことができない事由から生じた損害、当社の予見の有無を問わず特別の事情から生じた損害、逸失利益については、当社は賠償責任を負わないものとします。
                </p>
                <h3 className="p-article__title">第22条（免責）</h3>
                <ol>
                  <li className="p-article__description">
                    本サービス又は利用契約等に関して当社が負う責任は、理由の如何を問わず前条の範囲に限られるものとし、当社は、以下の事由によりお客様に発生した損害については、債務不履行責任、不法行為責任、その他の法律上の請求原因の如何を問わず賠償の責任を負わないものとします。
                    <ol>
                      <li className="p-article__description">
                        天災地変、騒乱、暴動等の不可抗力に起因して生じた損害
                      </li>
                      <li className="p-article__description">
                        お客様設備の障害に起因して生じた損害
                      </li>
                      <li className="p-article__description">
                        本サービス用設備からの応答時間等インターネット接続サービスの性能値に起因して生じた損害
                      </li>
                      <li className="p-article__description">
                        善良なる管理者の注意をもってしても防御し得ない本サービス用設備への第三者による不正アクセス又はアタック、通信経路上での傍受、コンピュータウィルスに起因して生じた損害
                      </li>
                      <li className="p-article__description">
                        当社が定める手順・セキュリティ手段等をお客様が遵守しないことに起因して発生した損害
                      </li>
                      <li className="p-article__description">
                        電気通信事業者の提供する電気通信役務の不具合に起因して発生した損害
                      </li>
                      <li className="p-article__description">
                        裁判所の命令若しくは法令に基づく強制的な処分に起因して生じた損害
                      </li>
                      <li className="p-article__description">
                        その他当社の責に帰すことのできない事由に起因して生じた損害
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    当社は、お客様が本サービスを利用することによりお客様と第三者との間で生じた紛争等について一切責任を負わないものとします。
                  </li>
                </ol>
              </div>
              <div className="p-terms__content">
                <h2 className="p-chapter__title">第7章 その他</h2>
                <h3 className="p-article__title">第23条（委託）</h3>
                <p className="p-article__description">
                  当社は、本サービスの提供に関して必要となる業務の全部または一部を、第三者に委託することができるものとします。
                </p>
                <h3 className="p-article__title">第24条（通知）</h3>
                <ol>
                  <li className="p-article__description">
                    当社からお客様への通知は、通知内容を電子メール、書面又は本サービスのホームページに掲載するなど、当社が適当と判断する方法により行います。
                  </li>
                  <li className="p-article__description">
                    前項の規定に基づき、当社からお客様への通知を電子メールの送信又は本サービスのホームページへの掲載の方法により行う場合には、お客様に対する当該通知は、それぞれ電子メールの発信又はホームページへの掲載がなされた時点から効力を生じるものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第25条（利用規約の変更）</h3>
                <p className="p-article__description">
                  当社は、利用規約を随時変更することができるものとします。利用規約が変更された場合、お客様の利用条件その他本利用契約の内容は、変更後の利用規約に従って定まるものとします。利用規約の変更を行う場合は、当社が当該変更をお客様に通知した日から、14日以上の予告期間を経過した時点より適用されるものとします。
                </p>
                <h3 className="p-article__title">
                  第26条（権利義務譲渡の禁止）
                </h3>
                <p className="p-article__description">
                  お客様は、あらかじめ当社の書面による承諾がない限り、利用契約上の地位又は利用契約に基づく権利若しくは義務の全部又は一部を、第三者に譲渡し、引き受けさせ、又は担保に供してはならないものとします。
                </p>
                <h3 className="p-article__title">第27条（反社会的勢力）</h3>
                <ol>
                  <li className="p-article__description">
                    お客様および当社は、相互に、次の各号のいずれにも該当しないこと、かつ、将来にわたっても該当しないことを確約します。
                    <ol>
                      <li className="p-article__description">
                        自己および自己の役員が反社会的勢力でないこと、また反社会的勢力でなかったこと
                      </li>
                      <li className="p-article__description">
                        自己および自己の役員が自己が不当な利得を得ることその他目的の如何を問わず、反社会的勢力の威力等を利用しないこと
                      </li>
                      <li className="p-article__description">
                        自己および自己の役員が反社会的勢力に対して資金を提供する等、反社会的勢力の維持運営に協力しないこと
                      </li>
                      <li className="p-article__description">
                        自己または自己の役員が反社会的勢力と社会的に非難されるべき関係を有しないこと
                      </li>
                      <li className="p-article__description">
                        自己および自己の役員が自らまたは第三者を利用して、相手方に対し暴力的な要求行為、法的な責任を超えた不当な要求を行い、相手方の名誉や信用を棄損せず、また相手方の業務を妨害しないこと
                      </li>
                    </ol>
                  </li>
                  <li className="p-article__description">
                    お客様および当社は、自己または自己の役員について前項各号に違反する事実が判明した場合には、相手方に直ちに通知するものとします。
                  </li>
                  <li className="p-article__description">
                    お客様および当社は、相手方が第１項に違反した場合は、何らの通知催告を要せず、直ちに利用契約の全部または一部を解除することができるものとします。
                  </li>
                  <li className="p-article__description">
                    お客様および当社は、相手方が第１項に違反したことにより損害を被った場合、当該相手方に対し、前項に基づく契約解除の有無にかかわらず、当該損害について本規約に基づく損害賠償を請求することができるものとします。
                  </li>
                </ol>
                <h3 className="p-article__title">第28条（合意管轄）</h3>
                <p className="p-article__description">
                  お客様と当社の間で訴訟、調停その他裁判所が関与する手続の必要が生じた場合には、東京地方裁判所を専属的合意管轄裁判所とします。
                </p>
                <h3 className="p-article__title">第29条（準拠法）</h3>
                <p className="p-article__description">
                  利用契約等の成立、効力、履行及び解釈に関する準拠法は、日本法とします。
                </p>
                <h3 className="p-article__title">第30条（分離可能性）</h3>
                <p className="p-article__description">
                  本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効となった場合であっても、本規約のその余の規定は継続して効力を有するものとします。
                </p>
                <h3 className="p-article__title">第31条（一部無効）</h3>
                <p className="p-article__description">
                  利用契約等のいずれかの部分が無効である場合でも、利用契約等全体の有効性には影響がないものとし、かかる無効の部分については、民法、商法等の法令を適用するものとします。
                </p>
                <h3 className="p-article__title">第32条（存続条項）</h3>
                <p className="p-article__description">
                  利用規約第6条第4項、第9条第2項、第11条、第13条、第19条乃至第21条及び第25条乃至本条の規定は、本利用契約の終了後もなお有効に存続するものとします。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default Terms;
